import * as React from "react";

import Layout from "../../components/Layout";
import { StaticQuery, graphql } from "gatsby";

export default class IndividualDepartureIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/blog-index.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: "0.5rem 0 0 #f40, -0.5rem 0 0 #f40",
              backgroundColor: "#f40",
              color: "white",
              padding: "1rem",
            }}
          >
            Latest Stories
          </h1>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <StaticQuery
                query={graphql`
                  query FixedDepartureRollQuery {
                    allMarkdownRemark(
                      sort: { order: DESC, fields: [frontmatter___title] }
                      filter: {
                        frontmatter: { templateKey: { eq: "fixed-departure" } }
                      }
                    ) {
                      edges {
                        node {
                          excerpt(pruneLength: 400)
                          id
                          fields {
                            slug
                          }
                          frontmatter {
                            title
                            templateKey
                            image {
                              childImageSharp {
                                gatsbyImageData(
                                  width: 120
                                  quality: 100
                                  layout: CONSTRAINED
                                )
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                `}
                render={(data, count) => <p>1</p>}
              />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
